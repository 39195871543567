import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../templates/layout";
import ContactMe from "../components/contactButton";

class FAQ extends React.Component{
    constructor(props){
        super(props);
        this.state = {disabled:1};

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        
        this.setState({disabled:Math.pow(0,this.state.disabled)})
        console.log(this.state.disabled)
    }

    render(){
        let show = this.state.disabled ? "hidden" : "bg-gray-200 rounded py-4 pl-5";
        return(
                <div onClick={this.handleClick} className="mx-auto max-w-xs sm:max-w-sm border-b-2 border-gray-400 border-dashed pb-6 mb-10 md:max-w-md lg:max-w-2xl xl:max-w-5xl">
                    <div className="flex justify-between">
                        <div className="text-lg max-w-xs md:max-w-lg text-left">
                            <button>{this.props.q}</button>
                        </div>
                        <div className="w-6">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 22.06"><title>Asset 3</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect x="9.01" y="-1.55" width="4.04" height="25.15" transform="translate(-4.57 11.03) rotate(-45)"/><path d="M3.57,1.41,20.64,18.49l-2.15,2.15L1.41,3.57,3.57,1.41M3.57,0,0,3.57,18.49,22.06l3.57-3.57L3.57,0Z"/><rect x="13.4" y="9.01" width="25.15" height="4.04" transform="translate(-0.19 21.59) rotate(-45)"/><path d="M33.43,1.41l2.15,2.15L18.51,20.64l-2.15-2.15L33.43,1.41m0-1.41L14.94,18.49l3.57,3.57L37,3.57,33.43,0Z"/></g></g></svg>
                        </div>
                    </div>
                    <div className={show}>
                            {this.props.a}
                    </div>
                </div>
        )
    }   
}

export default (props) => {
    const data = props.data.allContentfulFaqPage.nodes[0];
    const banner = data.banner;
    const faqs = data.faqs;
    return (<Layout title="FAQ" des="There are many questions about Insurance, Especially Medicare. I have attempted to make this easier with these frequently asked questions.">
        
        <div>
            <div className="bg-blue-900 pb-5 md:pb-10 mb-10 relative">
                    <Img fluid={banner.sizes} alt="Banner"/>
                    <h1 className="absolute text-2xl md:text-4xl lg:text-5xl text-white text-bold italic words-xs lg:words-md">
                    FAQ's</h1>
            </div>
            {faqs.map((faq)=> <FAQ q={faq.question} a={faq.answer.answer}/>)}

            <ContactMe msg="Have any additional questions? I'd love to help!"/>
        </div>
    </Layout>)
}


export const query = graphql`
    query{
        allContentfulFaqPage {
            nodes {
              banner {
                sizes(maxWidth: 1500, quality: 100) {
                    ...GatsbyContentfulSizes
                }
              }
              faqs {
                question
                answer {
                  answer
                }
              }
            }
          }
    }
`